a {
  text-decoration: none;
}

.background-color-elaborado {
  background-color: $elaborado-color;
}
.background-color-declarado {
  background-color: $declarado-color;
}
.background-color-recepcionado {
  background-color: $recepcionado-color;
}
.background-color-recepcionadofd {
  background-color: $recepcionadofd-color;
}
.background-color-recepcionadocd {
  background-color: $recepcionadocd-color;
}
.background-color-observado {
  background-color: $observado-color;
}
.background-color-rectificado {
  background-color: $rectificado-color;
}
.background-color-verificado {
  background-color: $verificado-color;
}
.background-color-eliminado {
  background-color: $eliminado-color;
}
