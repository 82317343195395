.mat-dialog-title {
    padding-bottom: .9rem;
}

.mat-dialog-actions {
    margin-top: .5rem;
    justify-content: right;
}


.modal-container {
    height: 100%;
    // padding: 0 .75rem 1rem;

    // .row {
    //     height: 100%;
    // }

    .modal__header {
        .modal__title {
            font-size: 1.3rem;
            font-weight: 500;
            line-height: 2;
        }
        .modal__subtitle {
            font-size: 0.8rem;
        }
    }

    &__modal {
        .modal-container__head {
          text-align: center;
          .head__logo {
              img {
              // width: 100%;
              width: 18rem;
              }
          }
          .head__title {
              font-size: 0.75rem;
          }
        }
    }

    &__body {
        margin: 0.5rem 0 0;
        .body__lead {
          .body__title {
              font-size: 1rem;
              text-align: left;
          }
          .body__legend {
              font-size: 0.75rem;
          }
        }
        .modal__mode {
            .mode-option {
                text-align: center;
                margin: 1.5rem 0;
                &__img {
                height: 5rem;
                margin: 0.75rem auto;
                text-align: center;
                }
                &__label {
                font-size: 0.7rem;
                }

                &__legend {
                text-align: center;
                    .legend__option {
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }

    &__footer {
        margin-top: 1.5rem;
        text-align: center;

        .footer__option {
        font-size: 0.7rem;
        }
    }
}