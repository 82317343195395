.tooltip-background {
  background: #303030;
  font-size: 12px;
  font-weight: 300;
}

.mat-tooltip {
  font-size: 12px;
  background-color: #303030;
  font-weight: 300;
}
