table {
  width: 100%;
  border-collapse: collapse;
}

.table-head-flex {
  display: flex;
  //justify-content: space-between;
}

.table-head-flex__child {
  /* border: thin solid red; */
}

tr.mat-header-row.header-row {
  text-align: center;
  border: 1px solid #dee2e6;
  background: #edeff1;
 
}

th {
 // text-align: left;
  color: #343a40;
  font-weight: 570;
 // border: 1px solid #dee2e6;
}

td {
  /* text-transform: capitalize; */
}

.mat-form-field {
  // width: 100%;
}

.mat-row:hover .mat-cell {
background: #bac7ce60;
 
 // color: #637985;
}