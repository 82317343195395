
// Style for Success button
.mat-button.mat-success,
.mat-stroked-button.mat-success {
  background-color: #fff;
  border: thin solid rgb(28 108 66);
  color: rgb(28 108 66);
  outline: 0;
  // border: 2px solid $success-color;
  // color: $success-color;
}
.mat-button.mat-success:hover,
.mat-stroked-button.mat-success:hover {
  background-color: lighten($color: rgb(28 108 66), $amount: 67);
  // background-color: #f0fff3;
}
.mat-raised-button.mat-success,
.mat-flat-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
  color: #f0fff3;
  background-color: $success-color;
}
.mat-icon-button.mat-success {
  color: $success-color;
  outline: 0;
}

// Style for Primary button
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $primary-color;
  color: white;
}

// Style for Primary-outline button
.mat-button.mat-primary-outline,
.mat-stroked-button.mat-primary-outline {
  border: thin solid $primary-color;
  color: $primary-color;
}
.mat-button.mat-primary-outline:hover,
.mat-stroked-button.mat-primary-outline:hover {
  background-color: #f0f0ff;
}
.mat-icon-button.mat-primary-outline {
  color: $primary-color;
  outline: 0;
}

// Style for Secondary button
.mat-flat-button.mat-secondary,
.mat-raised-button.mat-secondary,
.mat-fab.mat-secondary,
.mat-mini-fab.mat-secondary {
  background-color: $secondary-color;
  color: white;
}

// Style for Secondary-outline button
.mat-button.mat-secondary-outline,
.mat-stroked-button.mat-secondary-outline {
  border: thin solid $secondary-color;
  color: $secondary-color;
}
.mat-button.mat-secondary-outline:hover,
.mat-stroked-button.mat-secondary-outline:hover {
  background-color: lighten($color: $secondary-color, $amount: 50%)
}
.mat-icon-button.mat-secondary-outline {
  color: $secondary-color;
  outline: 0;
}

// Style for Simple-outline button (Cancel button)
.mat-button.mat-simple-outline,
.mat-stroked-button.mat-simple-outline {
  border: none;
  color: $primary-color;
}
.mat-button.mat-simple-outline:hover,
.mat-stroked-button.mat-simple-outline:hover {
  background-color: darken($color: #fff, $amount: 5%);
}
.mat-icon-button.mat-simple-outline {
  color: $primary-color;
  outline: 0;
}

// NUEVOS BOTONES

// Style for Warn-stroked button
.mat-button.mat-warn-outline,
.mat-stroked-button.mat-warn-outline {
  border: thin solid $warn-color;
  color: $warn-color;
}

.mat-button.mat-warn-outline:hover,
.mat-stroked-button.mat-warn-outline:hover {
  background-color: lighten($color: $warn-color, $amount: 30%)
}
.mat-icon-button.mat-warn-outline {
  color: $warn-color;
  outline: 0;
}
