$success-color: #37a745;
//$primary-color: #7B95A3;
$primary-color: #34b0a6;
$secondary-color: #34b0a6;
$danger-color: #f64032;
$warn-color: #ff4f58;
$warning-color: #f77f00;
$info-color: #20639b;
$ciudadania-color: #83af15;
$firmado-color: #430d4b;

$elaborado-color: $primary-color;
$declarado-color: $success-color;
$recepcionado-color: $info-color;
$recepcionadofd-color: $firmado-color;
$recepcionadocd-color: $ciudadania-color;
$observado-color: $danger-color;
$rectificado-color: $warning-color;
$verificado-color: $secondary-color;
$eliminado-color: $danger-color;