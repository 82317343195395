/* COLORES DE ESTADO */

.status-container {
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  span {
    font-size: 13px;
    /* &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: gray;
        margin-right: 0.5rem;
      } */
  }
  
}
$creado: #8a5340;
$solicitado:#54575a;
$estadosRegistros:#54575a;
$devolucionTramite:#54575a;
$anulacionTramite:#54575a;
$improcedente:#54575a;
$estadosAP:#54575a;

$subsanados:#54575a;
$elaborado: #008080;
$aprobado: #0b5345; //DARKGREEN
$declarado: #154360; //DARK AZUL
$preAprobado: #385dc2;
$elaborado:#333333;
$pagado: #2a6b2e;
$recepcionado: #00008b;
$recepcionadoCd: #4a235a; // PURPLE
$recepcionadoFd: #7e5109; //BROWN
$true: #866666;
$false: #5e5e5e;
$externo: #474747;
$servidorPublico: #474747;
$observado: #333333;
$aprobado:#54575a;
$estadosC:#3D4447;
.solicitado {
  color: $solicitado;
 // background-color: #839dac;
 background-color: #d7f5f3;
 // text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}

.estadosRegistros {
  color: $estadosRegistros;
  background-color: #e1f9fce7;
 // text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}
.estadosAP {
  color: $estadosAP;
  background-color: #e3f2f3d0;
 // text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}
.estadosC{
  color: $estadosC;
  background-color: #f3f3f3;
  // background-color: #839dac;
   // text-transform: uppercase;
   font-weight: 500;
   letter-spacing: .3px;
  // border-radius: 2px;
}
.improcedente {
  color: $improcedente;
  background-color: #f3f3f3;
 // text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}
.devolucionTramite {
  color: $devolucionTramite;
  background-color: #e1f9fce7;
 // text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}
.anulacionTramite {
  color: $anulacionTramite;
  background-color: #e1f9fce7;
 // text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}
.observado {
  color: $observado;
  background-color: #e9bef1;
   // text-transform: uppercase;
   font-weight: 500;
   letter-spacing: .3px;
  // border-radius: 2px;
}
.subsanados {
  color: $subsanados;
  background-color: #e7e5e5;
 // text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}
.pagado {
  color: $pagado;
  background-color:#A3E2C6;
 //  text-transform: uppercase;
  font-weight: 550;
  letter-spacing: .3px;
 // border-radius: 2px;
}
.elaborado {
  color: $elaborado;
  background-color: #bee5f1;
   // text-transform: uppercase;
   font-weight: 500;
   letter-spacing: .3px;
  // border-radius: 2px;
}
.creado {
  color: $creado;
  background-color: #FEEDAF;
 // text-transform: uppercase;
 font-weight: 550;
 letter-spacing: .3px;
// border-radius: 2px;
}
.true {
  color: $true;
  background-color: #ffc40091;
}

.false {
  color: $false;
  background-color: #d1d1d1;
}
.externo {
  color: $externo;
  background-color: #ca43f363;
}

.servidorPublico {
  color: $servidorPublico;
  background-color: #70bbf88e;
}
.aprobado {
  color: $aprobado;
  background-color: #54575a;
  font-weight: 550;
  letter-spacing: .3px;

}