/* Estilo para el boton de confirmar de Seet  */
.swal2-confirm.swal2-styled  {
  background-color: $primary-color !important;
  box-shadow: none !important;
}
/* Estilo para el boton Cancelar */
.swal2-cancel.swal2-styled {
  background-color: transparent !important;
  color: $primary-color !important;
  box-shadow: none !important;
}
/* Estilo css para el texto del Sweet alert */
.swal2-html-container {
  font-size: 1.05rem !important;
}