// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

@import "./app/styles/themes/theme.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ov-primary: mat.define-palette($color-accent);
$ov-accent: mat.define-palette($color-primary, 600, A100, A400);
// $ov-primary: mat.define-palette(mat.$indigo-palette);
// $ov-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ov-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ov-theme: mat.define-light-theme((color: (primary: $ov-primary, accent: $ov-accent, warn: $ov-warn, ), ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ov-theme);
@import "./app/styles/main";

/* You can add global styles to this file, and also import other style files */


/* @import '@angular/material/prebuilt-themes/indigo-pink.css'; */

html,
body {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: Roboto, "Arial", sans-serif;
  padding: 0;
}

$vh: 100vh;
$navbarHeight: 64px;

.main-container {
  /*   background: linear-gradient(150deg,#ffffff 22px,#bbbbbb 0,#bbbbbb 124px,transparent 0,transparent 67px,#bbbbbb 0,#bbbbbb 69px,transparent 0),linear-gradient(185deg,#ffffff 222px,#bbbbbb 0,#bbbbbb 24px,transparent 0,transparent 67px,#bbbbbb 0,#bbbbbb 69px,transparent 0) 0 ;
 
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  margin-top: 64px;
  padding: 1rem 2rem;  */
  background-image: linear-gradient(180deg, #dbd8d88c 0%, rgba(255, 255, 255, 0.712), #fbfdfd80 100%), url(assets/svg/abstract-2.svg);
  background-size: cover;
  min-height: calc(#{$vh} - #{$navbarHeight});
  height: 100%;
  background-color: #dadbdf;
  margin-top: 64px;
  padding: 1rem 2rem;

  .main__header {
    padding: 0.5rem 0;

    .main__title {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 2;
    }

    .main__subtitle {
      font-size: 0.8rem;
    }

  }

  .main__search {
    display: flex;
    align-items: center;
    margin: 1rem 0;

    .column {
      padding-left: 0;
    }

    .mat-form-field-wrapper {
      background-color: #fff;
      padding-bottom: 0;
      border: thin solid $primary-color;
      border-radius: 5px;
    }
  }

}

.NoexisteTramite {
  background: rgb(226, 226, 226);
  margin: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.nuevo-button {
  height: 45px;
 /*  background-color: #A3E2C6;
  border: 1px solid #80CAAA;
  color: #333333; */
  background-color: #34b0a6;
  border: 1px solid #336965;
  color: #fff;
  //opacity: 0.9;
  font-size: .84rem; 
  border-radius: 5px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

}

.edit-button {
  height: 45px;
  background: #ffe38e;
  border: 1px solid #ffd95e;
  color: #333333;
  // font-weight: 500;

}

.delete-button {
  background: rgb(243, 54, 54);
  // height:auto !important;
  // width:36px !important;
 // height: 40px;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loader {
  /* background: #424242; */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}

.hide {
  display: none;
}


/* mat-table */

mat-footer-row mat-footer-cell {
  justify-content: center;
  font-style: italic;
}


/* filter controls */

.search-div {
  margin: 10px;
}

.search-form-field {
  width: 60%;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.search-form-field div.mat-form-field-underline {
  display: none;
}

.search-form-field div.mat-form-field-infix {
  border-top: 0px;
}

.search-form-field div.mat-form-field-wrapper {
  padding-bottom: 0px;
}

.search-form-field div.mat-form-field-suffix button {
  height: 32px;
  width: 32px;
}

.tabla {
  border-color: rgb(172, 184, 194);
  border-style: solid;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  &.auth__page {
    background: #F5F5F5;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.auth-container {
  height: 100%;
  background: linear-gradient(135deg, #ffffff 22px, #e5e8ec 0, #e5e8ec 24px, transparent 0, transparent 67px, #e5e8ec 0, #e5e8ec 69px, transparent 0), linear-gradient(225deg, #ffffff 22px, #e5e8ec 0, #e5e8ec 24px, transparent 0, transparent 67px, #e5e8ec 0, #e5e8ec 69px, transparent 0) 0 64px;

  //background: linear-gradient(45deg, #00bfff, #7fffd4); 
  background-color: hsla(0, 0%, 100%, 0.863);
  background-size: 264px 238px;

  .row {
    height: 100%;
  }

  &__box {
    padding: 1.5rem 1.8rem;
    // width: 370px;
    width: 450px;
    padding: 40px;
    // box-shadow: 0 0 37px rgba(8,21,66,.05);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.281);
    border-radius: 10px;

    .auth-container__head {
      text-align: center;

      .head__logo {
        img {
          // width: 100%;
          width: 18rem;
          max-width: 100%;
        }
      }

      .head__title {
        font-size: 0.75rem;

        &.weight_title {
          font-weight: 700;
          font-size: 15px;
        }
      }
    }

    .auth-container__form form {
      width: 312px;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  &__modal {

    // border: thin solid #344563;
    // padding: 1.5rem 1.8rem;
    // border-radius: 8px;
    // width: 370px;
    // // width: 80vw;
    .auth-container__head {
      text-align: center;

      .head__logo {
        img {
          // width: 100%;
          width: 18rem;
        }
      }

      .head__title {
        font-size: 0.75rem;
      }
    }
  }

  &__body {
    margin: 1.5rem 0 0;

    .body__lead {
      .body__title {
        font-size: 1rem;
        text-align: center;

        &.other__title {
          font-weight: 700;
          font-size: 20px;
        }
      }

      .body__legend {
        font-size: 0.75rem;
        width: 356px;
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .auth__mode {
      .mode-option {
        text-align: center;
        margin: 1.5rem 0;

        &__img {
          height: 5rem;
          margin: 0.75rem auto;
          text-align: center;
        }

        &__label {
          font-size: 0.7rem;
        }

        &__legend {
          text-align: center;

          .legend__option {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: 1.5rem;
    text-align: center;

    .footer__option {
      // font-size: 0.7rem;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.modal-container {
  height: 100%;

  // .row {
  //     height: 100%;
  // }
  &__modal {
    .modal-container__head {
      text-align: center;

      .head__logo {
        img {
          // width: 100%;
          width: 18rem;
        }
      }

      .head__title {
        font-size: 0.75rem;
      }
    }
  }

  &__body {
    margin: 0.5rem 0 0;

    .body__lead {
      .body__title {
        font-size: 1rem;
        text-align: left;
      }

      .body__legend {
        font-size: 0.75rem;
      }
    }

    .modal__mode {
      .mode-option {
        text-align: center;
        margin: 1.5rem 0;

        &__img {
          height: 5rem;
          margin: 0.75rem auto;
          text-align: center;
        }

        &__label {
          font-size: 0.7rem;
        }

        &__legend {
          text-align: center;

          .legend__option {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: 1.5rem;
    text-align: center;

    .footer__option {
      font-size: 0.7rem;
    }
  }
}

.user-container {
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  .user-height {
    height: 100%;
  }

  &__box {
    // border: thin solid #d40d4f;
    box-shadow: 0 3px 7px #2b2b2b;
    padding: 1.5rem 1.8rem;
    border-radius: 8px;
    height: 550px;

    .user-container__head {
      text-align: center;

      .head__logo {
        img {
          // width: 100%;
          width: 18rem;
        }
      }

      .head__title {
        font-size: 0.75rem;
      }
    }
  }

  &__body {
    margin: 1.5rem 0 0;

    .body__lead {
      .body__title {
        font-size: 1rem;
        text-align: center;
      }

      .body__legend {
        font-size: 0.75rem;
      }
    }

    .user-container__form {
      .registro-usuario-stepper {
        padding-bottom: 0;
      }
    }

    .user__mode {
      .mode-option {
        text-align: center;
        margin: 1.5rem 0;

        &__img {
          height: 5rem;
          margin: 0.75rem auto;
          text-align: center;
        }

        &__label {
          font-size: 0.7rem;
        }

        &__legend {
          text-align: center;

          .legend__option {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: -1rem;
    text-align: center;

    .footer__option {
      font-size: 0.7rem;
    }
  }
}

.step-body {
  display: flex;
  flex-direction: column;
 //height: 300px;

  .step-contain {
    flex-grow: 4;
    // border: thin solid;
    display: flex;
    flex-direction: column;
    align-items: center;
   // margin-bottom: 0.7rem;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0;

      // flex-grow: 1;
      .row {
        flex-grow: 1;
        width: 100%;
        padding: 0;
      }
    }

    &--center {
      justify-content: center;
    }

    &--left-center {
      justify-content: center;
    }
  }

  .step-footer {
    text-align: right;
    height: 35px;
  }
}

app-file-upload {
  display: inline-block;
  margin-right: 1rem;
}

mat-tab-body {
  padding-top: 1.5rem;
}

.error-message {
  color: red;
  font-size: 0.7rem;
}

.color-yellow {
  color: wheat
}

.color-yellow-2 {
  color: rgb(247, 204, 112);
}

.color-yellow-3 {
  color: rgb(250, 172, 4);
}

.color-orange {
  color: rgba(230, 118, 44, 0.938);
}

.color-orange-2 {
  color: rgb(238, 156, 79);
}

.color-celeste-2 {
  color: rgb(109, 189, 221);
}

.color-red {
  color: rgb(190, 97, 97);
}

.color-celeste {
  color: rgb(106, 190, 241);
}

.color-azul {
  color: rgba(35, 35, 134, 0.849);
}

.color-rosa-3 {
  color: rgba(191, 69, 202, 0.849);
}

.color-azul-2 {
  color: rgba(88, 131, 165, 0.952);
}

.color-plomo {
  color: rgb(134, 137, 139);
}

.color-rosa {
  color: rgba(250, 90, 170, 0.849);
}

.color-rosa-2 {
  color: rgba(223, 52, 94, 0.849);
}

.color-verde-3 {
  color: rgba(53, 182, 64, 0.664);
}

.color-verde {
  color: rgb(3, 112, 3);
}

.color-verde-2 {
  color: rgb(84, 182, 157);
}

.color-morado {
  color: rgba(133, 145, 248, 0.952);
}

.color-morado-2 {
  color: rgba(163, 58, 204, 0.952);
}

.mat-sort-header-arrow {
  //color: #fff !important;
  color: #343a40;
  position: relative;
}

//   .ck-editor__editable {
//     min-height: 500px;
//     width: 100%;
// }

/*editor WYSINWIG*/
/* Container styles */
.document-editor {
  border: 0;
  border-radius: var(--ck-border-radius);
  display: flex;
  flex-flow: column nowrap;
}

/* Toolbar styles */
.document-editor__toolbar {
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Editable container styles */
.document-editor__editable-container {
  padding: 20px;
  /* Adjust the padding as needed */
  background: var(--ck-color-base-foreground);
  overflow-y: scroll;
}

/* Editable content styles */
.document-editor__editable-container .ck-editor__editable {
  //min-width: 20%;
  width: 100%;
  min-height: 50%;
  //height:100%;
  height: 27.94cm;
  // width: 100%;
  // min-height: 300px; /* Adjust the height as needed */
  border: 1px solid hsl(0, 0%, 82.7%);
  width: fit-content;
  border-radius: var(--ck-border-radius);
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 1 auto;
  //padding: 10px; /* Adjust the padding as needed */
  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;
  overflow-y: scroll;
}

/* Responsive styles for mobile devices */
@media only screen and (max-width: 600px) {
  .document-editor__editable-container {
    padding: 5px;
    /* Adjust the padding as needed for mobile */
  }

  .document-editor__editable-container .ck-editor__editable {
    padding: 5px;
    /* Adjust the padding as needed for mobile */
  }
}

/**/
//////////
.close-button {
  float: right;
  top: -20px;
  right: -20px;
}

table.table-list {
  width: 100%;

  thead {
    border: thin solid #bababa;

    tr {
      background-color: #eaebee;
      color: #fff;

      th {
        padding: 0.5rem 0;
        font-size: 12px;
        font-weight: normal;
        vertical-align: middle;
        text-align: center;
        width: 10%;
      }
    }
  }

  tbody {
    border: thin solid #bababa;

    tr {
      td {
        font-size: 13px;
        font-weight: normal;
        vertical-align: middle;
        text-align: center;
        border: none;
      }
    }
  }

  tfoot {

    //border: thin solid #234f83;
    tr {
      // background-color: #234f83;
      color: #616466;

      th {
        padding: 1rem 0;
        font-size: 13px;
        font-weight: normal;
        vertical-align: middle;
        text-align: center;
        width: 5%;
      }

      td {
        font-size: 15px;
        font-weight: normal;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
}

table.table-list2 {
  width: 100%;

  thead {
    border: thin solid #bababa;

    tr {
      th {
        background-color: #eaebee;
        border: thin solid #bababa;
        color: #615f5f;
        padding: 0.4rem 0;
        font-size: 11px;
        font-weight: normal;
        vertical-align: middle;
        text-align: left;
        padding-left: .75rem;
        width: 15%;
      }

      td {
        //color: rgb(5, 5, 5);
        //background-color: #ffffff;
        font-size: 12px;
        font-weight: normal;
        vertical-align: middle;
        text-align: left;
      }
    }
  }

}

table {
  width: 100%;

  .table-head-flex {
    display: flex;
    justify-content: space-between;
 
  }

  tr.mat-header-row.header-row {
    background-color: #eaebee;

    th {
      color: rgba(0, 0, 0, 0.54);
      height: 38px;
    }
  }


}

/* For screens smaller than 600px (i.e. mobile devices) */

@media (max-width: 900px) {

  /* Hide column headers */
  th {
    display: none;
  }

  /* Set table cells to be full-width and display as block elements */
  td {
    content: attr(data-header);
    //font-weight: bold;
    display: block;
    text-align: left;
    font-size: .8rem;
  }

  .mat-cell:before,
  .mat-header-cell:before {
    //content: attr(data-header);
    font-weight: bold;
    position: absolute;
    left: 6px;
    top: 6px;
    bottom: 6px;
    width: 50%;
  }

  /* Add styles for odd and even rows to differentiate them */
  tr:nth-child(odd) {
    background-color: #7b95a379;
  }

  tr:nth-child(even) {
    background-color: #f5f3f3da;
  }

  /* Add padding to the table cells to improve readability */
  td {
    padding: 12px 16px;

  }

}

.icon-png-button {
  height: 24px;
}


// New Style confe style
.btn__roller {
  width: 17px;
  height: 17px;
  margin: auto;
  line-height: normal;
  display: none;

  &.actived {
    display: block;
  }

  .spinner-border {
    width: 100%;
    height: 100%;
    vertical-align: unset;

    &.primary-color {
      color: $primary-color;
    }

    &.secondary-color {
      color: $secondary-color;
    }
  }
}

.btn__loader {
  &.loading {
    span.text__roller {
      display: none;
    }

    .btn__roller {
      display: block;
    }
  }

  .text__roller {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: auto;
    max-width: 100%;
    white-space: normal;
  }
}

.data_loader {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .btn__roller {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &::after {
    content: " ";
    position: absolute;
    top: 0%;
    left: 0%;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 50%;
    border: 6px solid var(--primary-color);
    border-color: var(--primary-color) transparent var(--primary-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Body New Style
:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
}

body {


  .btn__outline,
  .btn__normal,
  .btn__transparent {
    &.big__space {
      padding: 12px 18px;
      min-height: 54px;
    }
  }

  .btn__outline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    line-height: normal;
    background-color: transparent;
    border: 1px solid;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: auto;
      max-width: 100%;
      white-space: normal;
    }

    &.border__dotted {
      border-style: dotted;
    }

    &.btn__primary {
      border-color: $primary-color;
      color: $primary-color;

      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }

    &.btn__secondary {
      border-color: $secondary-color;
      color: $secondary-color;

      &:hover {
        background-color: $secondary-color;
        color: #fff;
      }
    }

    &.btn__green {
      border-color: #0E6F56;
      color: #0E6F56;

      &:hover {
        background-color: #0E6F56;
        color: #fff;
      }
    }

    mat-icon,
    span.icon {
      font-size: 22px;
      width: auto;
      height: auto;
    }

    span.icon svg,
    svg {
      fill: currentColor;

      path[fill] {
        fill: currentColor;
      }
    }
  }

  .btn__normal {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    line-height: normal;
    background-color: $primary-color;
    border: 1px solid;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;

    &:disabled {
      background-color: #dbdbdb !important;
      border-color: #dbdbdb !important;
      color: #919191 !important;
    }

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: auto;
      max-width: 100%;
      white-space: normal;
    }

    &.btn__primary {
      border-color: $primary-color;
      background-color: $primary-color;
      color: #fff;

      &:hover {
        border-color: $secondary-color;
        background-color: $secondary-color;
        color: #fff;
      }
    }

    &.btn__secondary {
      border-color: $secondary-color;
      background-color: $secondary-color;
      color: #fff;

      &:hover {
        border-color: $primary-color;
        background-color: $primary-color;
        color: #fff;
      }
    }

    &.btn__green {
      border-color: #0E6F56;
      background-color: #0E6F56;
      color: #fff;

      &:hover {
        border-color: lighten(#0E6F56, 10%);
        background-color: lighten(#0E6F56, 10%);
        color: #d63333;
      }
    }

    &.btn__red {
      border-color: #CA333A;
      background-color: #CA333A;
      color: #fff;

      &:hover {
        border-color: lighten(#CA333A, 10%);
        background-color: lighten(#CA333A, 10%);
        color: #fff;
      }
    }

    mat-icon,
    span.icon {
      font-size: 22px;
      width: auto;
      height: auto;
    }

    span.icon svg,
    svg {
      fill: currentColor;

      path[fill] {
        fill: currentColor;
      }
    }
  }

  .btn__transparent {
    box-shadow: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    line-height: normal;
    background-color: transparent;
    border: 1px solid;
    border-color: transparent;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: auto;
      max-width: 100%;
      white-space: normal;
    }

    &.btn__primary {
      border-color: transparent;
      background-color: transparent;
      color: $primary-color;

      &:hover {
        border-color: transparent;
        background-color: rgba(lighten($primary-color, 50%), 0.3);
        color: $primary-color;
      }
    }

    &.btn__secondary {
      border-color: transparent;
      background-color: transparent;
      color: $secondary-color;

      &:hover {
        border-color: transparent;
        background-color: rgba(lighten($secondary-color, 50%), 0.3);
        color: $secondary-color;
      }
    }

    mat-icon,
    span.icon {
      font-size: 22px;
      width: auto;
      height: auto;
    }

    span.icon svg,
    svg {
      fill: currentColor;

      path[fill] {
        fill: currentColor;
      }
    }
  }

  mat-form-field.mat-form-field {
    &.mat-form-field-disabled {
      div.mat-form-field-wrapper div.mat-form-field-flex {
        .mat-form-field-outline {
          border-color: #A5A5A5;

          .mat-form-field-outline-start {
            background: #F4F4F4;
          }

          .mat-form-field-outline-gap {
            background: #F4F4F4;
          }

          .mat-form-field-outline-end {
            background: #F4F4F4;
          }
        }
      }
    }

    input.big__text-input {
      color: #CA333A;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
  }

  .main__search.search-type__advance {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    .item__ad {
      width: auto;
      max-width: 100%;

      .mat-form-field {
        width: 820px;
        max-width: 100%;
      }
    }
  }

  .w-full {
    width: 100%;
  }

  .table__responsive {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .custom__upload {
    padding-bottom: 2rem;
  }

  .space__td {
    padding-left: 5px;
    padding-right: 5px;
  }

  .border__tr {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .upload_file-content {
    ul {
      padding: 1rem 0;

      li {
        mat-progress-bar {
          margin-bottom: 10px;

          .mat-progress-bar-fill::after {
            background-color: var(--secondary-color);
          }
        }

        #file-label {
          a {
            color: var(--primary-color);

            &[title="Cancel"] {
              color: #CA333A;
            }
          }
        }
      }
    }
  }

  .modal__center-logo {
    display: block;
    width: 100%;
    padding: 2rem 1rem;

    img {
      display: block;
      width: auto;
      max-width: 100%;
      margin: auto;
    }
  }

  .content_min-loader {
    width: 40px;
    margin: auto;

    .lds-dual-ring::after {
      border-width: 4px;
    }
  }

  .content__title-conciliacion {
    display: grid;
    grid-template-columns: minmax(0, 64px) minmax(0, 1fr);
    gap: 1rem;
    align-items: center;

    .content__title-logo {
      img {
        max-width: 100%;
      }
    }

    .content__title-text h1 {
      margin: 0;
      text-align: left !important;
      font-size: 20px;
    }
  }

  .mat-dialog-content .content__dc {
    p {
      font-size: 14px !important;

      span,
      strong,
      b {
        font-size: 20px;
      }
    }

    hr {
      width: 40%
    }
  }

  table {
    thead {
      tr.header__search {
        background: #FFFFE9;
        height: auto;

        th.th__input-search {
          padding: 0;
          background: #FFFFE9;
          border-left: 1px solid #ACACAC;
          border-right: 1px solid #ACACAC;

          &.disabled__th {
            background: rgba(0, 0, 0, 0.239);
            cursor: not-allowed;
          }

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }

          input {
            border: 0;
            padding: 10px;
            background: transparent;
            outline: none;
            width: auto;
            min-width: 100%;

            &:disabled {
              background: rgba(0, 0, 0, 0.169);
              cursor: not-allowed;
            }
          }
        }
      }
    }

    tr {
      &.active__radio {
        background-color: rgba(lighten($primary-color, 50%), 0.3) !important;

        td {
          background-color: rgba(lighten($primary-color, 50%), 0.3) !important;
        }
      }

      &.deleted_tr {
        background-color: rgba(lighten(#CA333A, 10%), 0.3) !important;

        td {
          background-color: rgba(lighten(#CA333A, 10%), 0.3) !important;
        }
      }
    }
  }

  // Switch
  .switch__input {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    overflow: hidden;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 2;

      &:checked+.switch__slider {
        background-color: var(--primary-color);
      }

      &:focus+.switch__slider {
        box-shadow: 0 0 1px var(--primary-color);
      }

      &:checked+.switch__slider span {
        transform: translateY(-50%) translateX(100%);
      }
    }

    .switch__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      z-index: 1;

      span {
        position: absolute;
        width: 43%;
        left: 6%;
        top: 50%;
        background-color: white;
        transition: 0.4s;
        transform: translateY(-50%);

        &::before {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }

      &.round {
        border-radius: 5rem;

        span {
          border-radius: 50%;
        }
      }
    }
  }

  .content__input-switch {
    display: inline-flex;
    align-items: center;
    gap: 1rem;

    .switch__input {
      width: 46px;
      height: 31px;
    }

    p {
      line-height: normal;
    }
  }

  mat-dialog-container.mat-dialog-container {
    max-height: 100%;
    height: auto;
  }

  .content__btns-comprobantes {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 1rem;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .text-lowercase {
    text-transform: lowercase;
  }

  .content__cp-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    .cp__data {
      width: max-content;
      max-width: 100%;
    }
  }

  .dtt_form-data-group {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: auto;
    max-width: 100%;

    &.end_align {
      align-items: flex-end;
    }
  }

  h6 {
    font-size: 15px !important;
  }

  .mat-form-field-disabled {
    pointer-events: none;
  }

  @media screen and (max-width: 767px) {
    .content__btns-comprobantes {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  @media screen and (max-width: 479px) {
    .dtt_form-data-group .dtt_form-group:not(.w-auto) {
      width: 100%;

      mat-form-field {
        width: 100%;
      }
    }
  }
}

.table-step {
  border: thin solid #d9d9d9;

  thead td {
    color: rgba(0, 0, 0, 0.54);
  }

  tr.mat-header-row {
    background-color: #eaebee;
  }

  tr,
  tr.mat-row,
  tr.mat-footer-row,
  tr.mat-header-row {
    height: 38px;
  }

  th,
  td {
    font-size: 13px;
  }
}

.snack-bar-color {
  width: 40px;
  /* Ajusta el tamaño de la imagen según tus necesidades */
  height: 40px;
  /* Esto garantiza que la imagen se redimensione correctamente */

}

.mat-snack-bar-container {
  // background-color: #fa9005b2;
  // color: #fff;

  font-size: 20px;
  /* Set the font size to 16 pixels */
  //width: 300px; /* Set the width of the snack bar to 300 pixels */
  //  height: 100px; /* Set the height of the snack bar to 50 pixels */
}

.mat-horizontal-stepper-header-container {
  white-space: nowrap;
  align-items: center;
  height: 50px;
  // margin-bottom: 20px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 5px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
}

.mat-radio-group.text-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.mat-radio-button {
  margin: 5px;
}

.title {
  //text-align: center;
  font-size: 0.8rem;
  background-color: #5dbcd436;
  border: #d0d0d1;
  border-radius: 7px;
  margin: 0em;
  padding: 8px 8px;
  color: #343a40;
  border-left: ridge;
}

.subtitle {
  color: rgb(65, 156, 134);
  font-size: 0.75rem;
}

.color-fill {
  color: #fff;
  background: #ff8c00;
  border: 1px solid #ff8c00;
  opacity: 0.9;
  border-radius: 5px;
}

.color-delete {
  color: #ffffff;
  background: #EF4444;
  border: 1px solid #EF4444;
  opacity: 0.7;
  border-radius: 5px;
}

.color-edit {
  color: #ffffff;
  background: #49ce8b;
  border: 1px solid #46c485;
 // opacity: 0.85;
  border-radius: 5px;
}

.color-print {
  color: #ffffff;
  background: #41cbdd;
  border: 1px solid #53d4e6;
 // opacity: 0.8;
  border-radius: 5px;
}

.color-upload {
  color: #ffffff;
  background: #df7ef7;
  border: 1px solid #d68be9;
  opacity: 0.85;
  border-radius: 5px;
}

.color-verified {
  color: #ffffff;
  background: #34b0a6;
  border: 1px solid #31aca1;
  // opacity: 0.85;
  border-radius: 5px;
}

.color-folder {
  color: #ffffff;
  background: #fcd915;
  border: 1px solid rgb(247, 215, 100);
  // opacity: 0.7;
  border-radius: 5px;
}

.color-red {
  color: #ffffff;
  background: #ee0505;
  border: 1px solid rgb(241, 7, 7);
  // opacity: 0.7;
  border-radius: 5px;
}

/* .mat-progress-bar-buffer {
  background: #e75460;
} */
.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: crimson;
}

.mat-progress-bar .mat-progress-bar-buffer {
  background: #f7ecef;
}

.mat-tab-label,
.mat-tab-label-active {
  // width: 20%!important;
  min-width: 100%;
  padding: 3px 5px;
  margin: 5px;
  color: #fff;
  background-color: #afb6b5;
  border-radius: 5px;
  font-size: 13px;
}

.mat-tab-label-active {
  color: #fff;
  background-color: #34b0a6;
  font-size: 13px;
}

.icon {
  &-print {
    color: #f77f00;
  }

  &-photo {
    color: #072446;
  }

  &-enable {
    color: #34b0a6;
  }

  &-delete {
    color: #f27272;
  }
}

.mat-form-field-appearance-outline.disabled .mat-form-field-outline {
  background-color: #eaebee;
  border-radius: 5px;
}
.mat-form-field-appearance-outline.header .mat-form-field-outline {
  background-color: #7ed1e618;
  border-radius: 5px;
  color:#34aac763;
  
}

.mat-icon-button .mat-button-focus-overlay {
  display: none;
}

/****asignacion***/
.main-card {
  background: #f5f4f4;
  margin: 0.1rem;
}

.inner-card {
  margin: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.inner-header {
  background: #ffffff;
}

.inner-title {
  background: #ffffff;
  color: #53575c;
  font-weight: bold;
}

.subtitle-text {
  font-size: 0.8rem;
  color: #6c757d;
}

.inner-content {
  margin: 0;
  padding: 5px 5px;
  text-align: left;
}

.imagen {
  background-color: #1fcce734;
  border-radius: 50%;
  padding: 5px; /* Agrega relleno si lo deseas */
}
.mat-grid-tile {
  display: flex;
  border: thin solid #b3b1b1;
  // border-radius: 5px;
//  margin: 0.1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 7.5px;
}
.hr-custom{
  color: #1fcce7ab;margin: 0; 
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #34b0a6;
}

.marker {
  // background-image: url('https://placekitten.com/g/40/40/');
  background-image: url('./assets/images/marker_140x140.png');
  // background-color: #2dc982;
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 180px;
  width: 180px;

  img {
    width: 100%;
  }

  &--description {
    margin-top: .7rem;
    text-align: center;
  }
  &--dateTime {
    // margin-top: .7rem;
    text-align: center;
  }
}
.alert {
  //text-align: center;
  font-size: 0.75rem;
  background-color: #feedaf9c;

  border-radius: 7px;
  margin: 0em;
  padding: 8px 8px;
  color: #8a5340;
 // border-width: 0 0 0 6px;
  border-left: solid;
}