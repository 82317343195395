mat-drawer {
    position: fixed;
    margin-top: 0px;
    z-index: 100;
    // max-width: 15rem;
  //  box-shadow: 0.1px 0.1px 1.5px $primary-color;
   // padding-right: .3rem;
    padding: 30px 10px;
}

mat-drawer-content {
    height: 100vh;
   
}

.mat-toolbar {
    position: fixed;
    background-color: #fff;
  //  color: #fff;
   background-image: linear-gradient( 100deg, #ffffff5b 100%, rgba(255, 255, 255, 0.356), #fbfdfd80 50% ),url(../../../assets/images/usercard.png);
   background-repeat:no-repeat;  
   background-position-x: 100%;
   background-position-y: 87%;
   background-size:40%;
    //color: $primary-color;
  //  box-shadow: 0px 2px 2px -1px $primary-color;
    z-index: 100;
    padding-left: 0.5rem;

    .mat-toolbar__username {
        font-size: 0.8rem;
        //text-transform: capitalize;
        font-weight: 300;
        color:#383737;
        text-align: right;
        height: 5rem; 
         line-height: 1rem;
          padding: 1.5rem;
          padding-right: 0.2rem;
        .phoneContent {
       
            width: 100%;
            height: 100px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }


}
@media all and (max-width: 600px) {
    .mat-toolbar__username {display:none;}
    .phoneContent {display:block;}
}
.example-spacer {
    flex: 1 1 auto;
}

.toolbar {  
   
    &__initial-background {
        display: inline-flex;
        align-items: center;
        justify-content: right;
        border-radius: 100%;
      
       //width: 45px;
      // height: 45px;  
        cursor: pointer;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
   
    }
    &__initial-user {
        display: inline-flex;
        align-items: center;
        justify-content: right;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $secondary-color;
        cursor: pointer;
        span {
            font-weight: 600;
            font-size: 16px; cursor: pointer;
            color: #fff;
        }
    }
    &__initial-notification {
        display: inline-flex;
        align-items: center;
        justify-content: right;
        border-radius: 50%;
        width: auto;
        height: auto;
       padding-right: 0.5rem;
        cursor: pointer;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      
    }
  
}

.menu {
    &__item--parent {
        min-width: 200px;
          /* Estilo para el enlace <a> dentro de .menu__item--parent en la primera posición */
          a:nth-child(1) {
            color: #f02323; /* Cambia color-menu-1 por el color que desees para el primer menú */
        }
        @at-root {
            a#{&} {
                margin-top: 0.3rem;
            }
        }
        span {
            color: #158d83;
            font-weight: 500;
            font-size: 0.8rem;
        }
    }
}

.submenu {
    &__item--child {
        @at-root {
            a#{&} {
                padding-left: 2.5rem !important;
            }
        }
        span {
            color: #0f6962;
        }
    }
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
    display: block;
    font-size: 0.8rem;
    height: 35px !important;
    width: min-content;
}


a:not(:link):not(:visited):active {
    background-color:rgb(52 176 166 / 48%); /* Cambiar el color de fondo al pasar el cursor sobre el enlace */ 

  }
  

  a:link,
  a:visited,
  a:active {
      text-decoration: none;
  }

  a.activo {
    background-color: rgba(54, 190, 179, 0.48);
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(129, 128, 128, 0.14), 0 4px 6px -5px rgba(54, 190, 179, 0.48);
    padding: 10px 15px;
  }
  
 
a.activo span {
   // color: $secondary-color;
   // color: #fff;
    font-size: 0.8rem;
    line-height: 20px;
    //font-weight: 500;
    //text-decoration: underline;
}


.dashboard-logo {
    display: flex;
    align-items: center;
    margin-right: 10px;
    &__image {
        margin: 0 0.0rem;
    }
    &__title {
        font-size: 1rem;cursor: pointer;
        //color: #0e0e0e;
        color: #383737;
        font-weight: 300;
        &:hover {
            color: #383737;           
        }
    }
    .phoneContent {
       
        width: 100%;
        height: 100px;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.sidebar__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
  
//color: #9FA6B2;
color:  #34b0a6;
}

.sidenav-container {
    position: fixed !important;
  //  background-color: $primary-color;
    
}